import request from '../../util/SuperagentUtils';
import { logout } from "../../util/LogoutUtil";

const handleFailure = (response) => {
  if (!response.ok) {
    throw Error(`${response.status} - ${response.statusText}`);
  }
  if (response.status === 401) {
    logout();
  }
  return response;
}

export const requestGetFailedTransactionsForBooking = (bookingId : String,
                                                       onSuccess?: (failedTransactions: Array<Object>) => void,
                                                       onFail?: (err: Object) => void) : (Promise<Object> | void) => {
    const result = request
        .get(`/api/booking/${bookingId}/transactions/failed`)

    if(onSuccess && onFail) {
        result
            .then((resp) => onSuccess(resp.body))
            .catch((err) => onFail(err))
    } else {
        return result
    }
}

export const requestBookingById = async (bookingId) => {
  return handleFailure(request.get(`/api/booking/${bookingId}`))
}

export const getFailedBookingTransactionFees: (
  bookingId: string,
  paymentMethodId: string,
  bookingTransactionIds: Array<String>
) => (Promise<Object> | void) = (bookingId, paymentMethodId, bookingTransactionIds) => {
  const searchParams = new URLSearchParams();
  searchParams.append('bookingId', bookingId);
  searchParams.append('paymentMethodId', paymentMethodId);
  if (bookingTransactionIds) searchParams.append('transactionIds', bookingTransactionIds.join(','));
  return request.get(`/api/booking-transaction/fee-summary?${searchParams.toString()}`);
}