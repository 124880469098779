import React, {memo} from 'react';
import {Checkbox, FormControlLabel, Link, Theme, Typography} from "@mui/material";
import {getAgreementUrl} from "../util/BookingUtil";
import {makeStyles} from "@mui/styles";

const useStyles: (theme: Theme) => {link: CSSStyleSheet} = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    fontSize: "inherit",
    color: theme.palette.secondary.main,
  }
}));

const TosAgreementLabel = memo(() => {
  const classes = useStyles();
  return (
    <Typography component={'span'}>
      I accept the <Link href={getAgreementUrl()} target="_blank" rel="noopener noreferrer" className={classes.link}>Rental Agreement</Link>.
    </Typography>
  );
});

type Props = {
  id?: string,
  name?: string,
  checked: boolean,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  required?: boolean,
  classes?: {formControlLabel?: string, checkbox?: string}
};

/**
 * Rental Agreement Checkbox
 * @param id the id of the checkbox element
 * @param name the name of the checkbox element
 * @param checked the checked state of the checkbox
 * @param onChange the change event handler
 * @param required determines whether the checkbox is required, will display an asterisk if true
 * @param classes the classes to apply to the checkbox and form control label
 * @returns {Element}
 * @constructor
 */
const RentalAgreementCheckbox = ({ id, name, checked, onChange, required, classes }: Props) => {
  return (
    <FormControlLabel
      className={classes?.formControlLabel || "ss-checkbox"}
      label={<TosAgreementLabel />}
      control={
        <Checkbox
          value={checked}
          checked={checked}
          onChange={onChange}
          id={id || "agreementAccepted"}
          name={name || "agreementAccepted"}
          required={required}
          className={classes?.checkbox || "ss-checkbox"}
        />
      }
    />
  );
};

export default RentalAgreementCheckbox;