import React, {useState} from 'react';
import { Box, Button, Grid, Typography, Link } from '@mui/material';
import {withSnackbar} from "../../hocs/withSnackbar";
import { getAgreementUrl } from '../../../util/BookingUtil.js';
import RentalAgreementCheckbox from "../../RentalAgreementCheckbox";
import { transactionDetailsStyles } from '../style'
import { formatMoneyAddDecimalPointAndThousands } from "../../../util/PaymentUtils";

const BookingPayment = ({ booking, paymentMethodId, paymentTotals, classes, handleMakePayment }) => {

  const styles = classes || transactionDetailsStyles();
  const [tosAgreed, setTosAgreed] = useState(false);

  const onTosChange = (e) => {
    setTosAgreed(e.target.checked);
  }

  return (
    <Box>
      <Grid item container direction={'column'} justifyContent='space-between' alignItems='center'>
        <Typography className={styles.heading}>
          Totals
        </Typography>

        <Grid container justifyContent='space-between' alignItems='flex-end' className={styles.bookDetailsRow}>
          <Grid container justifyContent='space-between' alignItems='center' className={styles.penIcon}>
            <Typography className={styles.title}>{"Number of Transactions"}</Typography>
            <Typography className={styles.title}>{`${paymentTotals.count}`}</Typography>
          </Grid>
          <Grid container justifyContent='space-between' alignItems='center' className={styles.penIcon}>
            <Typography className={styles.title}>{"Service Charges"}</Typography>
            <Typography className={styles.title}>{`${formatMoneyAddDecimalPointAndThousands(paymentTotals.charge)}`}</Typography>
          </Grid>
          <Grid container justifyContent='space-between' alignItems='center' className={styles.penIcon}>
            <Typography className={styles.title}>{"Fees"}</Typography>
            <Typography className={styles.title}>{`${formatMoneyAddDecimalPointAndThousands(paymentTotals.transactionFee)}`}</Typography>
          </Grid>
          <Grid container justifyContent='space-between' alignItems='center' className={styles.penIcon}>
            <Typography className={styles.title}>{"Taxes"}</Typography>
            <Typography className={styles.title}>{`${formatMoneyAddDecimalPointAndThousands(paymentTotals.tax)}`}</Typography>
          </Grid>
          <Grid container justifyContent='space-between' alignItems='center' className={styles.penIcon}>
            <Typography className={styles.title}>{"Total Charge"}</Typography>
            <Typography className={styles.title}>{`${formatMoneyAddDecimalPointAndThousands(paymentTotals.total)}`}</Typography>
          </Grid>
        </Grid>

        <Typography>Accept <Link to={'/terms-of-use'} target={"_blank"} className={styles.link}>Terms of Service</Link> Agreement</Typography>
        <RentalAgreementCheckbox
          checked={tosAgreed}
          required
          agreementUrl={getAgreementUrl(booking)}
          onChange={onTosChange}
          error={''}
        />
        <Button
            onClick={handleMakePayment}
            variant={"contained"}
            color={"secondary"}
            disabled={!tosAgreed || !paymentMethodId.length}
            sx={{ mt:3 }}
          >
            Make Payment
          </Button>
      </Grid>
    </Box>
  )
}

export default withSnackbar(BookingPayment)
