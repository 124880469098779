import React, {useState, useCallback, useEffect} from 'react'
import { bookingPaymentStyle } from './style'
import { Box, Button, Grid, Skeleton, Typography } from '@mui/material';
import { CreditCard } from "@mui/icons-material";
import BuyerPaymentMethodOption from './BuyerPaymentMethodOption';
import {determinePaymentImage} from "../../../util/PaymentUtils";
import {verbiage} from './verbiage';
import AddPaymentMethodModalRedesign from "./AddPaymentMethodModalRedesign";
import { requestPaymentMethods } from '../request/payment-method-requests';
import Busy from '../../Busy';
import {withSnackbar} from "../../hocs/withSnackbar";
import { logout } from "../../../util/LogoutUtil";
import {AuthorityType} from "../../constants/securspace-constants";
import {getErrorMessageForNonStandardAndStandardResponse} from "../../../util/NetworkErrorUtil";

const BuyerPaymentMethodSelect = ({ account, setSelectedPaymentMethod, snackbarShowMessage }) => {

  const classes = bookingPaymentStyle();

  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedMethodId, setSelectedMethodId] = useState('');

  const filterAndSetPaymentMethods = useCallback((paymentMethods) => {
    if (paymentMethods?.length) {
      paymentMethods = paymentMethods.filter((paymentMethod) => {
        return paymentMethod?.dwollaStatus !== 'unverified' && paymentMethod?.stripeStatus !== 'unverified';
      });
    }

    if (paymentMethods?.length) {
      setPaymentMethods(paymentMethods);
    } else {
      setPaymentMethods([]);
      snackbarShowMessage(verbiage.noPaymentMethodsError, 'error', 15000);
    }
  }, [snackbarShowMessage]);

  const loadPaymentMethods = useCallback(() => {
    Busy.set(true);
    requestPaymentMethods(
      account.authorityId || account.id,
      account.authorityType ? account.authorityType : AuthorityType.ACCOUNT,
      (response) => {
        filterAndSetPaymentMethods(response);
        Busy.set(false);
      },
      (error) => {
        if (error.status === 401) {
          logout();
        }
        const appError = getErrorMessageForNonStandardAndStandardResponse(error);
        console.error(appError);
        Busy.set(false);
      }
    )
  }, [account, filterAndSetPaymentMethods]);

  useEffect(() => {
    loadPaymentMethods();
  }, [loadPaymentMethods]);

  const handleSelectPaymentMethod = (value) => {
    setSelectedMethodId(value);
    setSelectedPaymentMethod(value);
  }

  const showAddPaymentModal = () => {
    setPaymentModalOpen(true);
  }

  const closeAddPaymentModal = (needsReload) => {
    setPaymentModalOpen(false);
    needsReload && loadPaymentMethods();
  }

  return (
    <Box>
      <Grid container justifyContent='space-between' alignItems='center'>
        <Typography variant={'h5'} color={'textPrimary'}>
          {verbiage.payWith}
        </Typography>
        <Button onClick={showAddPaymentModal} className={classes.button} variant='contained' color='secondary'>
          <CreditCard className={classes.payIcon} />
          {verbiage.addPayment}
        </Button>
      </Grid>
      {
        !paymentMethods.length ?
          <Skeleton variant={'text'} width={'100%'}/>
          : paymentMethods.length > 0 ?
            <Box className={classes.paymentSaveInfoContainer}>
              {
                paymentMethods.map((paymentMethod) => {
                    return (
                      <BuyerPaymentMethodOption
                        key={paymentMethod.id}
                        img={determinePaymentImage(paymentMethod)}
                        cardInfo={`...` + paymentMethod.lastFour + (paymentMethod.nickName ? ` (${paymentMethod.nickName})` : '')}
                        isSelect={selectedMethodId === paymentMethod.id}
                        id={paymentMethod.id}
                        setIsSelect={handleSelectPaymentMethod}
                      />
                    )
                  }
                )}
            </Box>
            :
            <Typography className={classes.noPayment}>
              {verbiage.noPaymentMethod}
            </Typography>
      }

      <AddPaymentMethodModalRedesign
        account={account}
        open={paymentModalOpen}
        onClose={closeAddPaymentModal}
      />
    </Box>
  )
}

export default withSnackbar(BuyerPaymentMethodSelect)
