import React, { Component } from 'react'
import Search from "../../views/Search"

class ContextualSearch extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return <Search />
    }
}

export default ContextualSearch
