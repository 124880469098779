import React, {useCallback, useEffect, useState, useContext} from 'react';
import {Button, Grid} from '@mui/material';
import {makeStyles} from '@mui/styles';
import LocationSearchListContent from '../components/search/LocationSearchListContent';
import SearchForm from '../components/SearchForm/SearchForm';
import {useLocation, useNavigate} from 'react-router-dom';
import {getByIpLocation, getLocationSuppliers} from '../components/location/requests/location-requests';
import {getErrorMessageForNonStandardAndStandardResponse} from '../util/NetworkErrorUtil';
import moment from 'moment';
import useUrlQueryParams from '../hooks/useUrlQueryParams';
import {getAddressParts} from '../util/AddressUtil';
import LocationSearchNoContent from '../components/LocationSearchNoContent';
import LocationMap from '../components/search/LocationMap';
import './search.css'
import {BsMap, BsList} from 'react-icons/bs'
import {DateFormats} from "../components/constants/securspace-constants";
import {LOCAL_STORAGE_LAT, LOCAL_STORAGE_LNG} from "../components/constants/local-storage-constants";
import {withSnackbar} from "../components/hocs/withSnackbar";
import {AppContext} from '../context/app-context';
import type {Supplier} from "../types/Supplier";
import SearchFilter from '../components/SearchFilter';
import {Helmet} from 'react-helmet';
import {geocodeCityState, geocodeLatLng, slugify} from '../util/GeoLocation';
import {Theme} from "@mui/material";
import {hasAdminAccess} from "../util/AccountUtils";


const DEFAULT_DAYS_AHEAD = 30;
const DEFAULT_NUM_SPACES = 1;
const DEFAULT_ERROR_MESSAGE = "Failed to fetch locations";
// Los Angeles, CA, USA
const DEFAULT_INIT_LAT = "34.0522342";
const DEFAULT_INIT_LNG = "-118.2436849";

const useStyles: (theme: Theme) => {
  searchResultsContainer: CSSStyleSheet,
  mainGridContainer: CSSStyleSheet,
  showSearchFilter: CSSStyleSheet
} = makeStyles(() => ({
  searchResultsContainer: {
    paddingTop: '.06em',
    overflow: 'hidden',
    maxHeight: 'inherit',
  },
  mainGridContainer: {
    minHeight: '75vh',
    maxHeight: '75vh',
    position: "relative"
  },
  showSearchFilter: {
    display: 'block',
    '@media (max-width:91.24em)': {
      display: 'none',
    }
  }
}))

const geocoder = new window.google.maps.Geocoder();


const Search = ({snackbarShowMessage}) => {
  const appContext = useContext(AppContext);
  const {lastSearchedLocation, setLastSearchedLocation} = appContext;
  const [showFilter, setShowFilter] = useState(false);
  const [filteredLocations: Supplier[], setFilteredLocations] = useState([]);
  const [unfilteredLocations: Supplier[], setUnfilteredLocations] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState(lastSearchedLocation.description || '');
  const [instantApproval, setInstantApproval] = useState(true);
  const [requestApproval, setRequestApproval] = useState(true);
  const [onlyLiveLocations, setOnlyLiveLocations] = useState(true);
  const [showHubSpotForm, setShowHubSpotForm] = useState(false);
  const [shouldFillSearchInput, setShouldFillSearchInput] = useState(true);
  const [isMapActive, setisMapActive] = useState(false)
  const [loading, setLoading] = useState(false);
  const [listHover, setListHover] = useState(null);
  const history = useNavigate();
  const {city, state} = useUrlQueryParams();
  const {user} = useContext(AppContext);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedEquipment, setSelectedEquipment] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryCity = queryParams.get("city");
  const queryState = queryParams.get("state");


  const getLocation = useCallback((place) => {
    let locationGeometry = place ? place.geometry : null;
    return locationGeometry ? locationGeometry.location : null;
  }, []);

  useEffect(() => {
    const setSearchInputBasedOnLatLng = (lat, lng) => {
      geocodeCityState(lat, lng)
        .then((latLng) => {
          const geocoderRequest = {
            location: {
              lat: parseFloat(latLng.latitude),
              lng: parseFloat(latLng.longitude),
            }
          };
          geocoder.geocode(geocoderRequest, (places, status) => {
            if (status === window.google.maps.GeocoderStatus.OK) {
              const place = places[0];
              const pacWithGeometry = {
                ...geocoderRequest,
                geometry: {...place.geometry},
                address_components: {...place.address_components}
              }
              const foundLocation = getLocation(pacWithGeometry);
              if (foundLocation) {
                const addressParts = getAddressParts(place);
                setSearchInputValue(`${addressParts.city}, ${addressParts.state}, ${addressParts.country}`);
              }
            }
          });
        })
        .catch((error) => {
          console.error('SearchInputBasedOnLatLng Error', error);
          setSearchInputBasedOnLatLng(DEFAULT_INIT_LAT, DEFAULT_INIT_LNG);
        });
    };

    const loadLastSearched = () => {
      const foundLocation = getLocation(lastSearchedLocation);

      if (!foundLocation) {
        history('/search');
      } else {
        geocodeLatLng(foundLocation.lat(), foundLocation.lng())
          .then((location) => {
            history(`/search?city=${location.city}&state=${location.state}`);
          })
          .catch((error) => {
            console.error('loadLastSearched Error', error);
            setSearchInputBasedOnLatLng(DEFAULT_INIT_LAT, DEFAULT_INIT_LNG);
          });
      }
    }

    const searchByIp = () => {
      try {
        getByIpLocation().then((response) => {
          let newLat = DEFAULT_INIT_LAT;
          let newLng = DEFAULT_INIT_LNG;
          if (response && response.ip && response.latitude && response.longitude) {
            newLat = response.latitude;
            newLng = response.longitude;
          }
          geocodeLatLng(newLat, newLng)
            .then((location) => {
              history(`/search?city=${location.city}&state=${location.state}`);
              setSearchInputBasedOnLatLng(newLat, newLng);
            });
        });
      } catch (error) {
        console.error('searchByIp Error:', error);
        setSearchInputBasedOnLatLng(DEFAULT_INIT_LAT, DEFAULT_INIT_LNG);
      }
    };

    if (city && state && shouldFillSearchInput) {
      // If user navigates to this page via URL only
      geocodeCityState(city, state)
        .then((latLng) => {
          setSearchInputBasedOnLatLng(latLng.latitude, latLng.longitude);
          setShouldFillSearchInput(false);
        })
        .catch((error) => {
          console.error('geocodeCityState Error:', error);
          setSearchInputBasedOnLatLng(DEFAULT_INIT_LAT, DEFAULT_INIT_LNG);
        });
    } else if (!city || !state) {
      if (lastSearchedLocation.description) {
        loadLastSearched()
      } else {
        let localStorageLat = localStorage.getItem(LOCAL_STORAGE_LAT);
        let localStorageLng = localStorage.getItem(LOCAL_STORAGE_LNG);

        if (localStorageLat && localStorageLng) {
          geocodeLatLng(localStorageLat, localStorageLng)
            .then((location) => {
              history(`/search?city=${location.city}&state=${location.state}`);
            })
            .catch((error) => {
              console.error('localStorageLat Error:', error);
            });
          setSearchInputBasedOnLatLng(localStorageLat, localStorageLng);
        } else if (window.navigator.geolocation) {
          // If user gives location permissions to our app
          window.navigator.permissions.query({name: "geolocation"}).then((result) => {
            if (result.state === "granted") {
              window.navigator.geolocation.getCurrentPosition((position) => {
                localStorage.setItem(LOCAL_STORAGE_LAT, position.coords.latitude);
                localStorage.setItem(LOCAL_STORAGE_LNG, position.coords.longitude);
                geocodeLatLng(position.coords.latitude, position.coords.longitude)
                  .then((location) => {
                    history(`/search?city=${location.city}&state=${location.state}`);
                    setSearchInputBasedOnLatLng(position.coords.latitude, position.coords.longitude);
                  })
                  .catch((error) => {
                    console.error('Permissions Error', error);
                  });
              }, searchByIp);
            } else {
              searchByIp();
            }
          });
        } else {
          searchByIp();
        }
      }
    }
  }, [getLocation, history, city, state, shouldFillSearchInput, lastSearchedLocation]);

  useEffect(() => {
    const filteredLocations = unfilteredLocations.filter((location: Supplier) => {
      if (!location.visible) {
        if (!hasAdminAccess(user) || onlyLiveLocations) return false;
      }
      let hasCapacity = true;
      if (!location.hasRequestedCapacity && !requestApproval) hasCapacity = false;
      if (location.hasRequestedCapacity && !instantApproval) hasCapacity = false;
      if (!hasCapacity) return false;

      let hasFeatures = true;
      if (selectedFeatures.length > 0) {
        hasFeatures = selectedFeatures.every((feature) => location.features.includes(feature));
      }
      if (!hasFeatures) return false;

      let hasEquipment = true;
      if (selectedEquipment.length > 0) {
        hasEquipment = selectedEquipment.every((equipment) => location.equipmentTypes.includes(equipment));
      }
      return hasEquipment;
    });
    setFilteredLocations(filteredLocations);
  }, [unfilteredLocations, instantApproval, requestApproval, user, onlyLiveLocations, selectedFeatures, selectedEquipment]);

  useEffect(() => {
    const getDateRange = (numDaysAhead: number) => {
      const today = moment();
      const formattedStartDate = today.format(DateFormats.DAY);

      const futureDate = today.add(numDaysAhead, 'day');
      const formattedEndDate = futureDate.format(DateFormats.DAY);

      return [formattedStartDate, formattedEndDate];
    };
    const fetchSuppliers = (location, startDate, endDate, numberOfSpaces) => {
      setLoading(true);
      getLocationSuppliers(location.lat, location.lng, startDate, endDate, numberOfSpaces).then((response) => {
        setShowHubSpotForm(!response.body.length);
        setUnfilteredLocations(response.body);
      }).catch((error) => {
        snackbarShowMessage(`${DEFAULT_ERROR_MESSAGE}: ${getErrorMessageForNonStandardAndStandardResponse(error)}`, 'error', 15000);
      }).finally(() => setLoading(false));
    };

    if (queryCity && queryState) {
      const [startDate, endDate] = getDateRange(DEFAULT_DAYS_AHEAD);
      geocodeCityState(queryCity, queryState)
        .then((latLng) => {
          fetchSuppliers({lat: latLng.latitude, lng: latLng.longitude}, startDate, endDate, DEFAULT_NUM_SPACES);
        })
        .catch((error) => {
          console.error('fetchSuppliers Error:', error);
        });
    }
  }, [queryCity, queryState, snackbarShowMessage]);

  const handleSearchSubmit = (pacItem) => {
    if (!pacItem?.place_id) return;
    setSearchInputValue(pacItem.description);
    geocoder.geocode({placeId: pacItem.place_id}, (place, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        const pacWithGeometry = {
          ...pacItem,
          geometry: {...place[0].geometry},
          address_components: {...place[0].address_components}
        }
        setLastSearchedLocation(pacWithGeometry);

        const foundLocation = getLocation(pacWithGeometry);

        if (!foundLocation) {
          history('/search');
        } else {
          history(`/search?city=${slugify(place[0].formatted_address.split(',')[0].trim())}&state=${place[0].formatted_address.split(',')[1].trim().toLowerCase()}`);
        }
      }
    });
  };

  const handleSearchValueChange = (value) => {
    setSearchInputValue(value);
  };

  const handleInstantApprovalChange = (isTrue) => {
    if (!isTrue && !requestApproval) {
      // At least one Boolean must be true otherwise we'll filter out all locations
      setRequestApproval(true);
    }
    setInstantApproval(isTrue);
  };

  const handleRequestApprovalChange = (isTrue) => {
    if (!isTrue && !instantApproval) {
      // At least one Boolean must be true otherwise we'll filter out all locations
      setInstantApproval(true);
    }
    setRequestApproval(isTrue);
  };

  const handleSelectLocation = (location) => {
    let queryParams = new URLSearchParams();
    if (location.hasRequestedCapacity && instantApproval) {
      queryParams.append("showMonth", false);
    }
    const newLink = `location-profile/${location.locationId}?${queryParams.toString()}`;
    window.open(newLink, "_blank");
  };

  const classes = useStyles();

  const toggleController = () => {
    setisMapActive(!isMapActive)
  }

  return (
    <>
      <Helmet>
        <title>{`Truck, trailer, & container storage in ${queryCity}, ${queryState} | SecurSpace `}</title>
        <meta name="description"
              content={`Secure intermodal container storage, drayage, trailer and truck parking with SecurSpace. Easy access to OTR parking storage solutions in ${queryCity}, ${queryState}`}/>
      </Helmet>
      <SearchForm
        onSearchSubmit={handleSearchSubmit}
        onSearchValueChange={handleSearchValueChange}
        onInstantApprovalChange={handleInstantApprovalChange}
        onRequestApprovalChange={handleRequestApprovalChange}
        onLiveLocationsChange={setOnlyLiveLocations}
        searchValue={searchInputValue}
        instantApprovalValue={instantApproval}
        requestApprovalValue={requestApproval}
        onlyLiveLocations={onlyLiveLocations}
        showAdminOnlyOptions={hasAdminAccess(user)}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        selectedEquipmentType={selectedEquipment}
        setSelectedEquipmentType={setSelectedEquipment}
        selectedLocationFeatures={selectedFeatures}
        setSelectedLocationFeatures={setSelectedFeatures}
      />
      <Grid container className={!showHubSpotForm ? classes.mainGridContainer : ''}>
        {showHubSpotForm ?
          <Grid container justifyContent='flex-start'>
            <Grid item xs={12} md={5}>
              <LocationSearchNoContent/>
            </Grid>
          </Grid>
          :
          <Grid container item direction="row" className={classes.searchResultsContainer}>
            <Grid item className={`ss-location-list-container ${isMapActive ? "ss-hide-list" : ""}`}>
              <LocationSearchListContent
                locations={filteredLocations}
                loading={loading}
                instantApproval={instantApproval}
                onlyLiveLocations={onlyLiveLocations}
                listHover={listHover}
                setListHover={setListHover}
              />
            </Grid>

            <Grid item className={`ss-mapBox ${isMapActive ? "ss-show-map" : ""}`}>
              <Grid className={classes.showSearchFilter}>
                {showFilter &&
                  <SearchFilter
                    selectedEquipmentType={selectedEquipment}
                    setSelectedEquipmentType={setSelectedEquipment}
                    selectedLocationFeatures={selectedFeatures}
                    setSelectedLocationFeatures={setSelectedFeatures}
                  />}
              </Grid>
              <LocationMap
                locations={filteredLocations}
                listHover={listHover}
                onLocationSelect={handleSelectLocation}
              />
            </Grid>
          </Grid>
        }
        {!showHubSpotForm &&
          <div className='ss-small-screen-controller'>
            <Button onClick={toggleController} variant='text'><span
              className={`ss-ctl-btn ${isMapActive ? "ss-ctl-btn-active" : ""} `}><BsMap/>  Map</span></Button>
            <Button onClick={toggleController} variant='text'><span
              className={`ss-ctl-btn ${!isMapActive ? "ss-ctl-btn-active" : ""} `}><BsList/> List</span></Button>
          </div>}
      </Grid>
    </>
  );
};

export default withSnackbar(Search);
