import React, {useCallback, useEffect, useState} from 'react';
import SecurSpaceContainer from "../../components/common/SecurSpaceContainer";
import {Grid, Typography} from "@mui/material";
import moment from "moment";
import {DateFormats} from "../../components/constants/securspace-constants";
import InvoiceList from "../../components/admin/invoices/redesign/InvoiceList";
import InvoiceSortFilter from "../../components/admin/invoices/redesign/InvoiceSortFilter/InvoiceSortFilter";
import InvoiceActionsDialogWrapper from "../../components/admin/invoices/redesign/InvoiceDialogs/InvoiceActionsDialogWrapper";
import {Pagination} from "@securspace/securspace-ui-kit";
import {requestAdminInvoices} from "../../requests/invoice-requests";
import useStyles from "./styles";
import InvoiceGroupSummary from "../../components/admin/invoices/redesign/InvoiceGroupSummary";
import ExportCSVs from "../../components/admin/invoices/redesign/ExportCSVs";
import {withSnackbar} from "../../components/hocs/withSnackbar";
import {getErrorMessageForNonStandardAndStandardResponse} from "../../util/NetworkErrorUtil";
import type {AdminInvoicesQueryType} from "../../components/admin/invoices/redesign/AdminInvoicesQueryType";
import {CallbackActions} from "../../components/admin/invoices/redesign/InvoiceActions";
import type {Account} from "../../types/Account";

type AdminInvoicesReportProps = {
  account: Account;
  handleLogout: () => void;
  snackbarShowMessage: any;
};

const defaultQuery: AdminInvoicesQueryType = {
  startDate: moment().subtract(1, 'month').format(DateFormats.DAY),
  endDate: moment().add(1, 'month').format(DateFormats.DAY),
  bookingNumber: '',
  transactionNumber: '',
  assetTypeGroup: '',
  buyerCompanyName: '',
  supplierCompanyName: '',
  transactionType: '',
  bookingStatus: '',
  supplierTransactionStatus: '',
  taxStatus: '',
  paymentProcessor: '',
  sortBy: 'transactionNumber',
  sortDir: 'desc',
  page: 0,
  size: 10,
};

const AdminInvoicesReport: (props: AdminInvoicesReportProps) => JSX.Element = ({account, handleLogout, snackbarShowMessage}) => {
  const [query: AdminInvoicesQueryType, setQuery] = useState({...defaultQuery});
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [totalNumberResults, setTotalNumberResults] = useState(0);
  const [page, setPage] = useState(0);
  const [activeDialog, setActiveDialog] = useState(null);
  const [activeInvoice, setActiveInvoice] = useState(null);

  const classes = useStyles();

  const getAdminInvoices = useCallback(() => {
    setLoading(true);
    requestAdminInvoices({...query, page})
      .then((response) => {
        const {content, totalPages, count, page} = response.body;
        if (page + 1 > totalPages) {
          // If the page index is greater than the total pages
          // This will refresh the data and show the last page of results
          setPage(totalPages > 0 ? totalPages - 1 : 0);
        }
        setInvoices(content);
        setTotalNumberResults(count);
      })
      .catch((err) => {
        if (err.response.status === 401) handleLogout();
        snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(err), 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [query, page, handleLogout, snackbarShowMessage]);

  useEffect(() => {
    getAdminInvoices();
  }, [getAdminInvoices]);

  const onInvoiceAction = (callbackAction, invoice, callBackActionDetail) => {
    switch (callbackAction) {
      case CallbackActions.LOGOUT:
        handleLogout();
        break;
      case CallbackActions.ERROR:
        snackbarShowMessage(callBackActionDetail, 'error');
        break;
      case CallbackActions.OPEN_DIALOG:
        setActiveInvoice(invoice);
        setActiveDialog(callBackActionDetail);
        break;
      case CallbackActions.SUCCESS:
        getAdminInvoices();
        snackbarShowMessage(callBackActionDetail, 'success');
        break;
      default:
        throw new Error('Invalid callback action');
    }
  }

  const onDialogClose = (...callbackActions) => {
    setActiveDialog(null);
    setActiveInvoice(null);
    if (callbackActions?.length)  {
      onInvoiceAction(...callbackActions);
    }
  }

  return (
    <SecurSpaceContainer>
      <InvoiceActionsDialogWrapper
        account={account}
        activeDialog={activeDialog}
        invoice={activeInvoice}
        onInvoiceAction={onInvoiceAction}
        onClose={onDialogClose}
      />
      <Grid container className={classes.mainContainer} rowGap={4}>
        <Typography variant={'h5'} component={'h1'}>Invoices</Typography>
        <InvoiceSortFilter
          initialQuery={defaultQuery}
          setQuery={setQuery}
        />
        <ExportCSVs query={query} />
        <InvoiceGroupSummary query={query} />
        <InvoiceList
          account={account}
          invoices={invoices}
          loading={loading}
          onInvoiceAction={onInvoiceAction}
        />
        <Grid item container justifyContent={'end'}>
          <Pagination
            count={totalNumberResults}
            rowsPerPage={query.size}
            page={page}
            onPageChange={(_event, page) => setPage(page)}
            onRowsPerPageChange={(event) => (setQuery((prevState) => ({...prevState, size: event.target.value})))}
            variant={'tablePagination'}
          />
        </Grid>
      </Grid>
    </SecurSpaceContainer>
  );
};

export default withSnackbar(AdminInvoicesReport);
