import React, {useState, useEffect} from 'react'
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import {getFailedBookingTransactionFees, requestGetFailedTransactionsForBooking} from '../payment-requests';
import Busy from '../../Busy';
import {withSnackbar} from "../../hocs/withSnackbar";
import TransactionItem from "./TransactionItem";
import { transactionDetailsStyles } from '../style'
import { getErrorMessageForNonStandardAndStandardResponse } from '../../../util/NetworkErrorUtil';

const TransactionsList = ({ bookingId, paymentMethodId, setPaymentsTotal, classes, snackbarShowMessage }) => {

  const styles = classes || transactionDetailsStyles()

  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTransactions = async () => {
      if (bookingId) {
        Busy.set(true);
        setLoading(true);
        try {
          const {body: transactionSummary} = await requestGetFailedTransactionsForBooking(bookingId);
          const sortedTransactions = transactionSummary.bookingTransactions.sort((a, b) => a.transactionNumber - b.transactionNumber);
          setTransactions(sortedTransactions);
          let total = {
            count: transactionSummary.failedTransactionCount,
            charge: transactionSummary.totalCharge,
            tax: 0,
            transactionFee: 0,
            total: transactionSummary.totalCharge
          };
          if (paymentMethodId) {
            const transactionIds = sortedTransactions.map(transaction => transaction.id);
            const {body: failedTransactionFees} = await getFailedBookingTransactionFees(bookingId, paymentMethodId, transactionIds);
            const totalTransactionAmount = failedTransactionFees.reduce((acc, transaction) => acc + transaction.transactionGrossAmount, 0);
            const totalTax = failedTransactionFees.reduce((acc, transaction) => acc + transaction.transactionTaxAmount, 0);
            const totalTransactionFee = failedTransactionFees.reduce((acc, transaction) => acc + transaction.paymentProcessorFeeAmount, 0);
            const totalCharge = failedTransactionFees.reduce((acc, transaction) => acc + transaction.totalCharge, 0);
            total = {
              count: transactionSummary.failedTransactionCount,
              charge: totalTransactionAmount,
              tax: totalTax,
              transactionFee: totalTransactionFee,
              total: totalCharge
            };
          }
          setPaymentsTotal(total);
        } catch (error) {
          snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error));
        } finally {
          Busy.set(false);
          setLoading(false);
        }
      }
    }
    fetchTransactions();
  }, [bookingId, paymentMethodId, setPaymentsTotal, snackbarShowMessage]);

  return (
    <Box>
      <Grid item container direction={'column'} justifyContent='space-between' alignItems='center'>
        <Typography className={styles.heading}>
          Transaction Details
        </Typography>
        {
          loading ?
            <Skeleton variant={'text'} width={'100%'}/>
          : !transactions.length ?
            <Typography className={styles.noTransactions}>No transactions found.</Typography>
          :
            <Box width={'100%'}>
              <Grid container justifyContent='space-between' alignItems='flex-end' className={styles.bookDetailsRow}>
                <Grid container justifyContent='space-between' alignItems='center' className={styles.penIcon}>
                  <Typography className={styles.title}>Invoice</Typography>
                  <Typography className={styles.title}>Service Dates</Typography>
                  <Typography className={styles.title}>Service Total Charge</Typography>
                </Grid>
              </Grid>
              {
                transactions.map((transaction) => <TransactionItem key={transaction.id} transaction={transaction} />)
              }
            </Box>
        }
      </Grid>
    </Box>
  )
}

export default withSnackbar(TransactionsList)
